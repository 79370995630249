/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@flaticon/flaticon-uicons/css/all/all.css";
/* import animate.css */
@import "../node_modules/animate.css/animate.min.css";

/* regular style toast */
@import 'ngx-toastr/toastr';

@import '../node_modules/video.js/dist/video-js.css';

/* video-js vjs_video_3-dimensions vjs-controls-enabled vjs-workinghover vjs-v8 vjs-playing vjs-has-started vjs-user-inactive */
.video-js {
    width: 100%;
    height: 100%;
}

:root,
[data-bs-theme="light"] {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #343a40;
    /* --bs-primary: #0d6efd; */
    /* --bs-primary: #FF9933; */
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    /* --bs-primary-rgb: 52, 58, 64; */
    --bs-primary-rgb: 255, 153, 51;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-primary-text-emphasis: #052c65;
    --bs-secondary-text-emphasis: #2b2f32;
    --bs-success-text-emphasis: #0a3622;
    --bs-info-text-emphasis: #055160;
    --bs-warning-text-emphasis: #664d03;
    --bs-danger-text-emphasis: #58151c;
    --bs-light-text-emphasis: #495057;
    --bs-dark-text-emphasis: #495057;
    --bs-primary-bg-subtle: #cfe2ff;
    --bs-secondary-bg-subtle: #e2e3e5;
    --bs-success-bg-subtle: #d1e7dd;
    --bs-info-bg-subtle: #cff4fc;
    --bs-warning-bg-subtle: #fff3cd;
    --bs-danger-bg-subtle: #f8d7da;
    --bs-light-bg-subtle: #fcfcfd;
    --bs-dark-bg-subtle: #ced4da;
    --bs-primary-border-subtle: #9ec5fe;
    --bs-secondary-border-subtle: #c4c8cb;
    --bs-success-border-subtle: #a3cfbb;
    --bs-info-border-subtle: #9eeaf9;
    --bs-warning-border-subtle: #ffe69c;
    --bs-danger-border-subtle: #f1aeb5;
    --bs-light-border-subtle: #e9ecef;
    --bs-dark-border-subtle: #adb5bd;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
        "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg,
            rgba(255, 255, 255, 0.15),
            rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-color-rgb: 33, 37, 41;
    /* --bs-body-bg: #fff;
    --bs-body-bg-rgb: 255, 255, 255; */
    --bs-body-bg: #eef1f9;
    --bs-body-bg-rgb: 238, 241, 249;
    --bs-emphasis-color: #000;
    --bs-emphasis-color-rgb: 0, 0, 0;
    --bs-secondary-color: rgba(33, 37, 41, 0.75);
    --bs-secondary-color-rgb: 33, 37, 41;
    --bs-secondary-bg: #e9ecef;
    --bs-secondary-bg-rgb: 233, 236, 239;
    --bs-tertiary-color: rgba(33, 37, 41, 0.5);
    --bs-tertiary-color-rgb: 33, 37, 41;
    --bs-tertiary-bg: #f8f9fa;
    --bs-tertiary-bg-rgb: 248, 249, 250;
    --bs-heading-color: inherit;
    --bs-link-color: #0d6efd;
    --bs-link-color-rgb: 13, 110, 253;
    --bs-link-decoration: underline;
    --bs-link-hover-color: #0a58ca;
    --bs-link-hover-color-rgb: 10, 88, 202;
    --bs-code-color: #d63384;
    --bs-highlight-color: #212529;
    --bs-highlight-bg: #fff3cd;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-xxl: 2rem;
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-focus-ring-width: 0.25rem;
    --bs-focus-ring-opacity: 0.25;
    --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
    --bs-form-valid-color: #198754;
    --bs-form-valid-border-color: #198754;
    --bs-form-invalid-color: #dc3545;
    --bs-form-invalid-border-color: #dc3545;
}
/*
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
} */
.btn-primary {
    --bs-btn-bg: #FF9933;
    --bs-btn-border-color: #FF9933;
    --bs-btn-color: #000;
    --bs-btn-hover-bg: #FF9933;
    --bs-btn-hover-border-color: #FF9933;
    --bs-btn-hover-color: #000;
    --bs-btn-focus-shadow-rgb: 255, 153, 51;
    --bs-btn-active-bg: #FF9933;
    --bs-btn-active-border-color: #FF9933;
    --bs-btn-active-color: #000;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-bg: #FF9933;
    --bs-btn-disabled-border-color: #FF9933;
    --bs-btn-disabled-color: #000;
}

html,
body {
    /* background-color: #eef1f9; */
    color: #4e5e6a;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13.5px;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    overflow-x: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

h1,
h2,
h3,
h4 {
    font-weight: normal;
}

h1,
h2,
h3 {
    margin: 10px 0;
}

h4,
h5,
h6 {
    margin: 10px 0;
}

h4 {
    font-size: 18px;
}

h5 {
    font-weight: bold;
}

blockquote {
    background: rgba(242, 242, 242, 0.42);
    font-size: 100%;
    margin: 0;
    padding: 10px 20px;
    border-left: 5px solid #eee;
}

pre {
    display: block;
    padding: 9.5px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    background-color: #f5f5f5;
    border-radius: 5px;
    border: none;
    margin: 0;
    white-space: normal;
}

.invisible {
    visibility: hidden;
}

a {
    color: #29689e;
    text-decoration: none;
}

a:hover,
a:active,
a:focus {
    text-decoration: none;
}

a:active {
    outline: none;
}

a.dark {
    color: #4e5e6a;
}

a.dark:hover {
    color: #000;
}

.dark {
    color: #595959;
}

.strong {
    font-weight: bold !important;
}

.text-off {
    opacity: 0.6;
}

.uppercase {
    text-transform: uppercase;
}

p {
    margin-bottom: 0.65rem;
}

small {
    font-size: 0.825rem;
}

.hide {
    display: none !important;
}

a {
    cursor: pointer;
}

.card {
    border: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    /* border: 1px solid #FF993355; */
}

.card-header {
    background-color: #FCDF9D;
    border-bottom: 0;
    padding: 0.75rem 1.25rem;
}

input.ng-invalid.ng-touched {
    border-color: var(--bs-danger);
    color: var(--bs-danger);
}

.ng-invalid.ng-touched~.invalid-feedback {
    display: block;
}

/* ng-untouched ng-pristine ng-invalid ng-submitted */
.ng-invalid.ng-submitted~.invalid-feedback {
    display: block;
}

.btn:focus,
.btn:active,
.btn:hover {
    outline: none;
    border: none;
}

/* Basic button styles */
.three-d-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
}

.cursor-pointer {
    cursor: pointer;
}

/* Button hover ease in animation */
.btn {
    border: 0 !important;
    transition: all 0.3s ease;
}
.btn:hover:not(.no-hover) {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
}

/* on link hove add underline animation */
a {
    text-decoration: none;
    position: relative;
    color: #3498db;
    transition: color 0.3s;
}
a:hover {
    color: #2980b9;
}

/* Add the underline */
a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #3498db;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}
a:hover::before {
    visibility: visible;
    transform: scaleX(1);
}
.modal.show {
    display: block;
    z-index: 99999999999;
    background: #3333;
}